import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AbstractRestService } from "./abstractRestService"
import { Painting } from "../model/painting.model"
import { IServiceInterface } from './serviceInterface';

@Injectable()
export class PaintingService extends AbstractRestService<Painting> implements IServiceInterface<Painting>{

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    super(http, baseUrl + "api/painting/");
  }
}
