<ul class="cb-slideshow" style="background-color:#000">
  <li><span>Image 01</span><!--<div><h3>re·lax·a·tion</h3></div>--></li>
  <li><span>Image 02</span><!--<div><h3>qui·e·tude</h3></div>--></li>
  <li><span>Image 03</span><!--<div><h3>bal·ance</h3></div>--></li>
  <li><span>Image 04</span><!--<div><h3>e·qua·nim·i·ty</h3></div>--></li>
  <li><span>Image 05</span><!--<div><h3>com·po·sure</h3></div>--></li>
  <li><span>Image 06</span><!--<div><h3>se·ren·i·ty</h3></div>--></li>
</ul>
<div class="c-container">
  <div class="codrops-top">
    <span class="right">
      <a routerLink="/{{localizationService.currentLang}}/about">
        <strong>{{'About me' | translate }}</strong>
      </a>
      <a routerLink="/{{localizationService.currentLang}}/contact">
        <strong> {{'Contact' | translate }}</strong>
      </a>
      <a class="icon-margin" href="https://www.instagram.com/kasperwieliczko/">
        <i class="icone-instagram icone-social-media-main-page"></i>
      </a>
      <a class="mr-1" href="https://www.facebook.com/kasper.wieliczko/">
        <i class="icone-facebook icone-social-media-main-page"></i>
      </a>
      <div class="d-inline-block" ngbDropdown #myDrop="ngbDropdown">
        <button class="lang-btn lang-btn-primary mr-2" id="dropdownManual" ngbDropdownAnchor (click)="myDrop.open()">
          <img style="max-height: 15px; vertical-align: sub" [src]="localizationService.getflag(localizationService.currentLang)" />
          <strong>
            {{localizationService.currentLang}}
          </strong>
        </button>
        <div class="lang-select" ngbDropdownMenu aria-labelledby="dropdownManual">
          <button ngbDropdownItem *ngFor="let language of localizationService.langs" (click)="localizationService.switchLang(language)">
            <img style="max-height: 15px; vertical-align: sub" [src]="localizationService.getflag(language)" />
            {{language}}
          </button>
        </div>
      </div>
    </span>
    <div class="clr"></div>
  </div>
  <header>
    <h1 class="text-shadow">Kasper Wieliczko</h1>
    <!--<h2>Only routine is worth something</h2>-->
    <ul class="codrops-demos text-shadow">
      <li><a routerLink="/{{localizationService.currentLang}}/photography">{{'Photography' | translate }}</a></li>
      <li><a routerLink="/{{localizationService.currentLang}}/painting">{{'Painting' | translate }}</a></li>
      <li><a routerLink="/{{localizationService.currentLang}}/ceramics">{{'Ceramics' | translate }}</a></li>
    </ul>
  </header>
</div>
