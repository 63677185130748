import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Email } from '../model/email.model';
import { AbstractRestService } from './abstractRestService';
import { IServiceInterface } from './serviceInterface';

@Injectable({
  providedIn: 'root'
})
export class ContactService extends AbstractRestService<Email> implements IServiceInterface<Email>{

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    super(http, baseUrl + "api/contact/");
  }
}
