import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { SlideShowComponent } from './slide-show/slide-show.component';

import { PhotoComponent } from './photo/photo.component';
import { PaintingComponent } from './painting/painting.component';
import { CeramicsComponent } from './ceramics/ceramics.component';
import { AboutMeComponent } from './about-me/about-me.component'

import { PhotoService } from './services/photo.service';
import { CeramicsService } from './services/ceramics.service';
import { PaintingService } from './services/painting.service';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgbCollapseModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AdminModule } from './admin/admin.module'
import { ShareModule } from './share/share.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LocalizationService } from './services/localization.service';
import { ContactComponent } from './contact/contact.component';
import { RecaptchaModule, RecaptchaSettings, ReCaptchaV3Service, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { AuthGuard } from './authentication/guards/auth-guard.service';
import { JwtModule } from '@auth0/angular-jwt';
import { getBaseUrl } from '../main';
import { AuthenticationService } from './services/authentication.service';
import { MainPageComponent } from './main-page/main-page.component';
import { GlobalErrorHandler } from './handlers/global-error-handler';
import { PhotoMagnifyComponent } from './photo/photo-magnify/photo-magnify.component';
import { SEOService } from './services/SEO.Service';

export function tokenGetter() {
  return localStorage.getItem("jwt");
}
//localhost
//const GoogleReCaptchaSiteKey = '6LfZt0gnAAAAAJv0Toe1nUf5J9Ph9y43KpOa29rx';
//kasperwieliczko.com
const GoogleReCaptchaSiteKey = '6Lea37MnAAAAAJe9nWvc1_Go2CRuPmJ4aM_nDXWW';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    SlideShowComponent,
    PhotoComponent,
    PaintingComponent,
    CeramicsComponent,
    AboutMeComponent,
    ContactComponent,
    MainPageComponent,
    PhotoMagnifyComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    NgbCollapseModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    InfiniteScrollModule,
    ShareModule,
    RecaptchaModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    RouterModule.forRoot([
      { path: '', component: MainPageComponent },
      { path: "adminpanel", loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule), canActivate: [AuthGuard] },
      { path: 'authentication', loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule) },

      { path: ':lang/photography/:id', component: PhotoMagnifyComponent },
      { path: 'photography/:id', component: PhotoMagnifyComponent },
      { path: ':lang/photography', component: PhotoComponent },
      { path: 'photography', component: PhotoComponent },

      { path: ':lang/painting', component: PaintingComponent },
      { path: 'painting', component: PaintingComponent },

      { path: ':lang/ceramics', component: CeramicsComponent },
      { path: 'ceramics', component: CeramicsComponent },

      { path: ':lang/about', component: AboutMeComponent },
      { path: 'about', component: AboutMeComponent },

      { path: ':lang/contact/:subject', component: ContactComponent },
      { path: 'contact/:subject', component: ContactComponent },
      { path: ':lang/contact', component: ContactComponent },
      { path: 'contact', component: ContactComponent },
      { path: '**', component: MainPageComponent }
    ],
      { relativeLinkResolution: 'legacy' }),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: [getBaseUrl()],
        //blacklistedRoutes: [getBaseUrl() + '/login']
      }
    }),
  ],
  providers: [
    PhotoService,
    PaintingService,
    CeramicsService,
    LocalizationService,
    AuthGuard,
    AuthenticationService,
    SEOService,
    TranslateModule,
    {
      // processes all errors
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    ReCaptchaV3Service,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: GoogleReCaptchaSiteKey
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
