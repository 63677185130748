import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Log } from "../model/log.model";

@Injectable()
export class LoggerService {
  private URL: string;

  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.URL = baseUrl + "api/logger/";
  }


  public log = (body: Log): Observable<Log> => {
    body.message = body.message ? body.message + this.handleError(body.error) : this.handleError(body.error);
    body.stackTrace = this.tryToGetStackTrace(body.error);
    return this._http.post<Log>(this.URL + 'log', body);
  }

  private handleError(error: any) {
    let msg = "additional info:";

    if (!error) {
      return msg + 'undefined error';
    }
    else if (typeof error === 'string') {
      return error;
    }
    else {
      if (error.message) {
        msg += `message: '${error.message}', `
      }

      if (error.status) {
        msg += `status: '${error.status}', `
      }

      if (error.statusText) {
        msg += `statusText: '${error.statusText}', `
      }

      return msg
    }
  }

  private tryToGetStackTrace(error: any) {
    return error.stack ? error.stack : 'Undefined';
  }
}
