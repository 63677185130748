import { Component, OnInit } from '@angular/core';
import { SEOService } from '../services/SEO.service';
import { LocalizationService } from '../services/localization.service';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.css']
})
export class MainPageComponent implements OnInit {
  private canonicalUrl: string = 'https://kasperwieliczko.com';
  public title: string = 'Kasper Wieliczko';
  public description: string = 'Ble ble, srutu rutu, tu tu tu'
  constructor(public localizationService: LocalizationService, private SEOService: SEOService) { }

  ngOnInit(): void {
    this.SEO();
  }

  SEO() {
    this.SEOService.setTitle(this.title);
    this.SEOService.updateMetaTag({ name: 'description', content: this.description });
    this.SEOService.createLinkForCanonicalURL(this.canonicalUrl);
  }
}
