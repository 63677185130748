<nav class="navbar navbar-expand-lg navbar-dark codrops-top">
  <strong>
    <a class="navbar-brand" routerLink="/{{localizationService.currentLang}}">Kasper Wieliczko</a>
  </strong>
  <button class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-label="Toggle navigation"
          [attr.aria-expanded]="isExpanded"
          (click)="toggle()">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div style="white-space: nowrap;" class="collapse navbar-collapse justify-content-end" id="navbarNavDropdown" [ngClass]="{ show: isExpanded }">
    <ul class="navbar-nav mr-5">
      <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
        <strong>
          <a class="nav-link" routerLink="/{{localizationService.currentLang}}/photography">{{'Photography' | translate }}</a>
        </strong>
      </li>
      <li class="nav-item" [routerLinkActive]="['link-active']">
        <strong>
          <a class="nav-link" routerLink="/{{localizationService.currentLang}}/painting">{{'Painting' | translate }}</a>
        </strong>
      </li>
      <li class="nav-item" [routerLinkActive]="['link-active']">
        <strong>
          <a class="nav-link" routerLink="/{{localizationService.currentLang}}/ceramics">{{'Ceramics' | translate }}</a>
        </strong>
      </li>
      <li class="nav-item" [routerLinkActive]="['link-active']">
        <strong>
          <a class="nav-link" routerLink="/{{localizationService.currentLang}}/about">{{'About me' | translate }}</a>
        </strong>
      </li>
      <li class="nav-item" [routerLinkActive]="['link-active']">
        <strong>
          <a class="nav-link" routerLink="/{{localizationService.currentLang}}/contact">{{'Contact' | translate }}</a>
        </strong>
      </li>
    </ul>
    <a href="https://www.instagram.com/kasperwieliczko/">
      <i class="icone-instagram icone-social-media-nav-menu"></i>
    </a>
    <a href="https://www.facebook.com/kasper.wieliczko/">
      <i class="icone-facebook icone-social-media-nav-menu"></i>
    </a>
    <div class="d-inline-block ml-1" ngbDropdown #myDrop="ngbDropdown">
      <button class="lang-btn lang-btn-primary mr-2" id="dropdownManual" ngbDropdownAnchor (click)="myDrop.open()">
        <img style="max-height: 15px; vertical-align: sub" [src]="localizationService.getflag(localizationService.currentLang)" />
        <strong>
          {{localizationService.currentLang}}
        </strong>
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownManual">
        <button ngbDropdownItem *ngFor="let language of localizationService.langs" (click)="localizationService.switchLang(language)">
          <img style="max-height: 15px; vertical-align: sub" [src]="localizationService.getflag(language)" />
          {{language}}
        </button>
      </div>
    </div>
  </div>
  <span role="button" *ngIf="isAuthenticated" class="float-right" (click)="logOut()">Hello {{user}}! Logout</span>
</nav>
