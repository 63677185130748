<app-nav-menu></app-nav-menu>
<app-slide-show [images]="images"></app-slide-show>

<div class="search-bar container">
  <ul class="nav justify-content-center">
    <li role="button" class="nav-item" (click)="filtr(polishLandscape.value)">
      <strong>
        {{'PolishLandscape' | translate }}
      </strong>
      <input hidden #polishLandscape value="{{'PolishLandscapeTags' | translate }}">
    </li>
    <li role="button" class="nav-item" (click)="filtr(landscapeOfEurope.value)">
      <strong>
        {{'LandscapeOfEurope' | translate }}
      </strong>
      <input hidden #landscapeOfEurope value="{{'LandscapeOfEuropeTags' | translate }}">
    </li>
    <li role="button" class="nav-item" (click)="filtr(polishArchitecture.value)">
      <strong>
        {{'PolishArchitecture' | translate }}
      </strong>
      <input hidden #polishArchitecture value="{{'PolishArchitectureTags' | translate }}">
    </li>
    <li role="button" class="nav-item" (click)="filtr(architectureOfEurope.value)">
      <strong>
        {{'ArchitectureOfEurope' | translate }}
      </strong>
      <input hidden #architectureOfEurope value="{{'ArchitectureOfEuropeTags' | translate }}">
    </li>
    <li role="button" class="nav-item" (click)="filtr(people.value)">
      <strong>
        {{'People' | translate }}
      </strong>
      <input hidden #people value="{{'PeopleTags' | translate }}">
    </li>
    <!--<li>
      <form class="form-inline search-form" #searchForm="ngForm">
        <input class="form-control search-form-input" type="text" [(ngModel)]="searchTags" name="tags" placeholder="search" aria-label="search">
        <button class="btn-icone" type="submit"><i class="icone-magnifying-glass"></i></button>
      </form>
    </li>-->
    <li class="relative">
        <input type="text" autocomplete="off" class="searchinput yui-ac-input add-padding" [(ngModel)]="searchTags" placeholder="{{'SearchForImages' | translate}}">
        <button [hidden]="!searchTags" class="absolute search-clear-icon" type="button" style="display: inline-block;" (click)="cleanTags()"><i class="icon-close"></i></button>
        <button class="absolute search-btn" type="button" (click)="filtrForm()"><i class="icone-magnifying-glass search-icon"></i></button>
    </li>
  </ul>
</div>

<div class="container">
  <div class="c-container">
    <div class="go-to-top" [style.visibility]="doGoToTopHidden ? 'hidden' : 'visible'" (click)="goToTop()">&uarr;</div>
    <section infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [infiniteScrollDisabled]="disableInfiniteScroll" (scrolled)="onScroll()">
      <div role="button" class="c-div" *ngFor="let photo of photosList" style="width:{{photo.width*250/photo.height}}px; flex-grow:{{photo.width*250/photo.height}}" (click)="openModal(content, photo)">
        <i class="c-i" style="padding-bottom:{{photo.height/photo.width*100}}%"></i>
        <img class="c-img" [src]="photo.imageUrl" alt="{{photo.photoDetails[0].name}}, {{photo.photoDetails[0].tags}}"/>
      </div>
    </section>
    <app-progress-spinner [hidden]="doSpinnerHidden"></app-progress-spinner>
    <div role="button" [hidden]="hideShowMoreBtn" class="btn-show-more btn-primary" (click)="doShowMore()">{{'SeeMorePhotos' | translate}}</div>
    <div [hidden]="!noResults" class="not-found"><strong>{{'NoResults' | translate}}</strong></div>
  </div>
</div>

<div [hidden]="hideFooter" class="footer-link-list container">
  <div class="row">
    <div class="col-6" style="text-align:right">
      <div role="button" class="footer-link-list-item" (click)="filtr(gdansk.value)">
        <strong>
          {{'Gdansk' | translate }}
        </strong>
        <input hidden #gdansk value="{{'Gdansk' | translate }}">
      </div>
      <div role="button" class="footer-link-list-item" (click)="filtr(switzerland.value)">
        <strong>
          {{'Switzerland' | translate }}
        </strong>
        <input hidden #switzerland value="{{'Switzerland' | translate }}">
      </div>
      <div role="button" class="footer-link-list-item" (click)="filtr(sweden.value)">
        <strong>
          {{'Sweden' | translate }}
        </strong>
        <input hidden #sweden value="{{'Sweden' | translate }}">
      </div>
      <div role="button" class="footer-link-list-item" (click)="filtr(poland.value)">
        <strong>
          {{'Poland' | translate }}
        </strong>
        <input hidden #poland value="{{'Poland' | translate }}">
      </div>
    </div>
    <div class="col-6">
      <div role="button" class="footer-link-list-item" (click)="filtr(germany.value)">
        <strong>
          {{'Germany' | translate }}
        </strong>
        <input hidden #germany value="{{'Germany' | translate }}">
      </div>
      <div role="button" class="footer-link-list-item" (click)="filtr(rome.value)">
        <strong>
          {{'Rome' | translate }}
        </strong>
        <input hidden #rome value="{{'Rome' | translate }}">
      </div>
      <div role="button" class="footer-link-list-item" (click)="filtr(denmark.value)">
        <strong>
          {{'Denmark' | translate }}
        </strong>
        <input hidden #denmark value="{{'Denmark' | translate }}">
      </div>
      <div role="button" class="footer-link-list-item" (click)="filtr(france.value)">
        <strong>
          {{'France' | translate }}
        </strong>
        <input hidden #france value="{{'France' | translate }}">
      </div>
    </div>
  </div>
</div>

<app-footer [hidden]="hideFooter" [footerPosition]="footerPosition"></app-footer>
<!--<app-footer [hidden]="hideFooter" [footerPosition]="noResults ? 'fixed' : 'relative'"></app-footer>-->

<ng-template #content let-modal>
  <button type="button" class="close c-close" aria-label="Close" (click)="modal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="row-box">
    <div class="col-left centre">
      <app-progress-spinner [hidden]="doSpinnerHidden" class="absolute"></app-progress-spinner>
      <img [src]="photoModal.imageUrl" />
    </div>
    <div class="col-right">
      <a role="button" class="c-carousel-control-prev link-area-extend" (click)="prevImg()" [hidden]="prevHidden">
        <span aria-hidden="true" class="c-arrow-left-bg-white wh-30"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a role="button" class="c-carousel-control-next link-area-extend" (click)="nextImg()" [hidden]="nextHidden">
        <span aria-hidden="true" class="c-arrow-right-bg-white wh-30"></span>
        <span class="sr-only">Next</span>
      </a>

      <div *ngFor="let photoDetails of photoModal.photoDetails">
        <div *ngIf="photoDetails.language == localizationService.currentLang">
          <ul class="img-info">
            <li>
              <div class="row">
                <div class="col-sm-6 img-info-left">
                  <span class="">{{'Title' | translate }}:</span>
                </div>
                <div class="col-sm-6 img-info-right">
                  <span class="font-weight-bold" title="title">{{photoDetails.name}}</span>
                </div>
              </div>
            </li>
            <li>
              <div class="row">
                <div class="col-sm-6 img-info-left">
                  <span class="">ID:</span>
                </div>
                <div class="col-sm-6 img-info-right">
                  <span>{{photoModal.id}}</span>
                </div>
              </div>
            </li>
            <li>
              <div class="row">
                <div class="col-sm-6 img-info-left">
                  <span class="">{{'Location' | translate }}:</span>
                </div>
                <div class="col-sm-6 img-info-right">
                  <span role="button" class="link" *ngFor="let tag of getLocation(photoDetails)" (click)="filtrModal(tag)">#{{tag}} </span>
                </div>
              </div>
            </li>
            <li>
              <div class="row">
                <div class="col-sm-6 img-info-left">
                  <span class="">{{'Tags' | translate }}:</span>
                </div>
                <div class="col-sm-6 img-info-right">
                  <span role="button" class="link" *ngFor="let tag of splitTags(photoDetails.tags)" (click)="filtrModal(tag)">#{{tag}} </span>
                </div>
              </div>
            </li>
            <li>
              <div class="row">
                <div class="col-sm-6 img-info-left">
                  <span class="">{{'NumberOfCopies' | translate }}:</span>
                </div>
                <div class="col-sm-6 img-info-right">
                  <span>1 / 1</span>
                </div>
              </div>
            </li>
          </ul>

          <p>{{'PricePhoto' | translate: {pricePLN: photoModal.pricePLN} }}<sup>2</sup></p>
          <p>
            <span role="button"
                  class="c-btn w-100 font-weight-bold"
                  (click)="collapse.toggle()"
                  [attr.aria-expanded]="isCollapsed"
                  aria-controls="collapseExample">
              {{'SaleConditions' | translate}}
              <span class="info_40"></span>
            </span>
          </p>
          <div>
            <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed" [horizontal]="true">
              <p class="text">{{'Conditions' | translate}}</p>
            </div>
          </div>
          <span role="button" (click)="navigateToContact(photoModal.id)" class="c-btn font-weight-bold" style="font-size: 11px;">{{'AskForPhoto' | translate}}</span>
          <p class="font-weight-bold text copy-rights">&copy; {{'AllRightsReserved' | translate}}</p>
        </div>
      </div>
    </div>
  </div>
</ng-template>
