<app-nav-menu></app-nav-menu>
<app-slide-show [images]="slideShowImages"></app-slide-show>

<div class="search-bar container">
  <ul class="nav justify-content-center">
    <li role="button" class="nav-item" (click)="filtr(oil.value)">
      <strong>
        {{'Oil' | translate }}
      </strong>
      <input hidden #oil value="{{'OilTags' | translate }}">
    </li>
    <li role="button" class="nav-item" (click)="filtr(ink.value)">
      <strong>
        {{'Ink' | translate }}
      </strong>
      <input hidden #ink value="{{'InkTags' | translate }}">
    </li>
    <li role="button" class="nav-item" (click)="filtr(acrylic.value)">
      <strong>
        {{'Acrylic' | translate }}
      </strong>
      <input hidden #acrylic value="{{'AcrylicTags' | translate }}">
    </li>
    <li class="relative">
      <input type="text" autocomplete="off" class="searchinput yui-ac-input add-padding" [(ngModel)]="searchTags" placeholder="{{'SearchForPainting' | translate}}">
      <button [hidden]="!searchTags" class="absolute search-clear-icon" type="button" style="display: inline-block;" (click)="cleanTags()"><i class="icon-close"></i></button>
      <button class="absolute search-btn" type="button" (click)="filtrForm()"><i class="icone-magnifying-glass search-icon"></i></button>
    </li>
  </ul>
</div>

<div class="container">
  <div class="go-to-top" [style.visibility]="doGoToTopHidden ? 'hidden' : 'visible'" (click)="goToTop()">&uarr;</div>
  <div infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [infiniteScrollDisabled]="disableInfiniteScroll" (scrolled)="onScroll()">
    <div *ngFor="let painting of paintingsList" class="row-box">
      <div class="col-left centre">
        <app-progress-spinner [hidden]="!painting.imagePagination.isLoading" class="absolute"></app-progress-spinner>
        <img style="min-height:200px" *ngIf="painting.imagePagination.currentImage.mediaType == mediaType.Photo else videoTemplate"
             [src]="painting.imagePagination.currentImage.value" (load)="load(painting.imagePagination)" alt="{{painting.paintingDetails[0].name}}, {{painting.paintingDetails[0].tags}}, abstract painting"/>
        <ng-template #videoTemplate>
          <video autoplay loop muted controls="controls">
            <source [src]="painting.imagePagination.currentImage.value" type="video/mp4">
            <source src="movie.ogg" type="video/ogg">
          </video>
        </ng-template>
        <p class="child-img-counter">{{painting.imagePagination.currentImage.key}}/{{painting.imagePagination.numberOfImages}}</p>
        <a role="button" class="carousel-control-prev" (click)="prevImg(painting.imagePagination)" [hidden]="painting.imagePagination.currentImage.key === 1">
          <span aria-hidden="true" class="c-arrow-left-bg-white wh-30"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a role="button" class="carousel-control-next" (click)="nextImg(painting.imagePagination)" [hidden]="painting.imagePagination.currentImage.key === painting.imagePagination.numberOfImages">
          <span aria-hidden="true" class="c-arrow-right-bg-white wh-30"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
      <div class="col-right">
        <div *ngFor="let paintingDetails of painting.paintingDetails">
          <div *ngIf="paintingDetails.language == localizationService.currentLang">
            <ul class="img-info">
              <li>
                <div class="row">
                  <div class="col-sm-6">
                    <span class="text-uppercase">{{'Title' | translate }}:</span>
                  </div>
                  <div class="col-sm-6">
                    <span class="font-weight-bold" title="title">{{paintingDetails.name}}</span>
                  </div>
                </div>
              </li>
              <li>
                <div class="row">
                  <div class="col-sm-6">
                    <span class="text-uppercase">ID:</span>
                  </div>
                  <div class="col-sm-6">
                    <span>{{painting.id}}</span>
                  </div>
                </div>
              </li>
              <li>
                <div class="row">
                  <div class="col-sm-6">
                    <span class="text-uppercase">{{'Size' | translate }}:</span>
                  </div>
                  <div class="col-sm-6">
                    <span>{{painting.width}} x {{painting.height}} cm</span>
                  </div>
                </div>
              </li>
              <li>
                <div class="row">
                  <div class="col-sm-6">
                    <span class="text-uppercase">{{'Technique' | translate }}:</span>
                  </div>
                  <div class="col-sm-6">
                    <span>{{paintingDetails.technique}}</span>
                  </div>
                </div>
              </li>
              <li *ngIf="paintingDetails.tags">
                <div class="row">
                  <div class="col-sm-6">
                    <span class="text-uppercase">{{'Tags' | translate }}:</span>
                  </div>
                  <div class="col-sm-6">
                    <span role="button" class="link" *ngFor="let tag of splitTags(paintingDetails.tags)" (click)="filtr(tag)">#{{tag}} </span>
                  </div>
                </div>
              </li>
            </ul>

            <p>{{'PricePainting' | translate: {pricePLN: painting.pricePLN} }}<sup>2</sup></p>
            <span role="button" (click)="navigateToContact(painting.id)" class="link" style="font-size: 11px;">{{'AskForPainting' | translate}}</span>
            <p class="font-weight-bold text copy-rights">&copy; {{'AllRightsReserved' | translate}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-progress-spinner [hidden]="doSpinnerHidden"></app-progress-spinner>
  <div [hidden]="!noResults" class="not-found"><strong>{{'NoResults' | translate}}</strong></div>
</div>

<app-footer [hidden]="hideFooter" [footerPosition]="noResults ? 'fixed' : 'relative'"></app-footer>
