import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { PhotoDetails } from '../model/photo-details.model';
import { Photo } from '../model/photo.model';
import { LocalizationService } from '../services/localization.service';
import { PhotoService } from '../services/photo.service';
import { SEOService } from '../services/SEO.service';

@Component({
  selector: 'app-photo',
  templateUrl: './photo.component.html',
  styleUrls: ['./photo.component.css']
})
export class PhotoComponent implements OnInit {
  private canonicalUrl: string = 'https://kasperwieliczko.com/photography';
  public title: string = 'Photography';
  public description: string = 'Kasper Wieliczko stock of my images. Thousands of new, high-quality pictures added every day. Best photos of Poland, Gdansk and not only, landscape and architecture'
  public photoModal: Photo;
  public photosList: Photo[] = [];
  public nextHidden: boolean;
  public prevHidden: boolean;
  public disableInfiniteScroll: boolean = false;
  public showFooter: boolean = false;
  public showMore: boolean = false;
  public doSpinnerHidden: boolean = true;
  public doGoToTopHidden: boolean = true;
  private readonly batchSize: number;
  private skip: number;
  private isLoading: boolean = false;
  public currentLang: string = "en";
  public searchTags: string = "";
  public hideShowMoreBtn: boolean = true;
  public hideFooter: boolean = true;
  public noResults: boolean = false;
  public images = []
  private doModalOpened: boolean = false;
  public isCollapsed = true;
  public footerPosition;

  constructor(private photoService: PhotoService, public localizationService: LocalizationService, private router: Router, activeRoute: ActivatedRoute,
    private modalService: NgbModal, private SEOService: SEOService) {
    this.skip = 0;
    this.batchSize = 20;

    this.currentLang = activeRoute.snapshot.params["lang"];
    if (this.currentLang && this.currentLang !== localizationService.currentLang) {
      localizationService.switchLang(this.currentLang);
    }
    if (!this.currentLang) {
      localizationService.setUpLangUrl();
    }
  }

  ngOnInit() {
    this.SEO();
    this.sliderShow();
    this.getPhotosBatch();
  }

  SEO() {
    this.SEOService.setTitle(this.title);
    this.SEOService.updateMetaTag({ name: 'description', content: this.description });
    this.SEOService.createLinkForCanonicalURL(this.canonicalUrl);
 }

  onScroll() {
    this.getPhotosBatch()
  }

  @HostListener("window:scroll", ["$event"])
  onWindowScroll() {
    let innerHeight = window.innerHeight; //height of the window, height of the visible screen
    let scrollY = window.scrollY; //scolled down pixels
    let offsetHeight = document.body.offsetHeight; //height of the whole document, from the top to the bottom to which user need to scroll down
    let offset = scrollY / innerHeight;

    if (offset >= 0.6) {
      this.doGoToTopHidden = false
    }
    else {
      this.doGoToTopHidden = true
    }

    // if you're at the bottom of the page
    if ((innerHeight + scrollY) >= offsetHeight && !this.disableInfiniteScroll) {
      //this.getPhotosBatch();
    }
  }

  getPhotosBatch(): Observable<Photo[]> {
    this.doSpinnerHidden = false;
    this.disableInfiniteScroll = true;

    const batch = this.photoService.getBatch(this.batchSize, this.photosList.length, this.searchTags).pipe(shareReplay());

    batch.subscribe(result => {
      if (this.searchTags === "" && !this.showMore) {
        this.photosList.push(...result.filter(e => e.priority === 'Ultra'));
        let endOfUltra: boolean = result.filter(e => e.priority === 'Ultra').length === 0;
        this.disableInfiniteScroll = endOfUltra;
        this.hideShowMoreBtn = !endOfUltra;
      }
      else {
        this.photosList.push(...result);
        this.disableInfiniteScroll = result.length === 0;
        this.hideShowMoreBtn = true;
      }

      if (this.photosList.length === 0) {
        this.noResults = true;
        this.hideShowMoreBtn = true;
      }
      else {
        this.noResults = false;
      }

      this.hideFooter = !this.disableInfiniteScroll;

      if (result.length > 0 && result.length < this.batchSize) {
        this.hideFooter = false;
      }

      this.doSpinnerHidden = true;
    }, error => {
      this.doSpinnerHidden = true;
      throw error;
    });

    return batch;
  }

  doShowMore() {
    this.showMore = true;
    this.disableInfiniteScroll = false;
    this.getPhotosBatch();
  }

  openModal(content, photo) {
    this.photoModal = photo;
    this.setArrows();
    this.doGoToTopHidden = true;

    let modalWidthClass: string = this.setModalWidth();
    let modalRef = this.modalService.open(content, { modalDialogClass: modalWidthClass, centered: true })

    modalRef.shown.subscribe(result => { this.doModalOpened = true; })

    modalRef.result.then((result) => {
      this.doModalOpened = false; this.onWindowScroll()
    }, (reason) => {
      this.doModalOpened = false; this.onWindowScroll()
    });

    //.shown.subscribe(result => { this.setModalWidth()})
    //.result.then((result) => { }, (reason) => { });
  }


  @HostListener('window:keydown', ['$event'])
  KeyboardEventHandler(event: KeyboardEvent) {
    if (this.doModalOpened) {

      switch (event.key) {
        case "Enter":
        case " ": //space
        case "ArrowRight":
        case "ArrowDown":
          this.nextImg();
          break;
        case "ArrowLeft":
        case "ArrowUp":
          this.prevImg();
          break;
      }
    }
  }

  prevImg() {
    let inderxOf = this.photosList.indexOf(this.photoModal);
    if (inderxOf == 0) {
      return;
    }

    this.photoModal = this.photosList[inderxOf - 1];
    this.setModalWidth();
    this.setArrows();
  }

  nextImg() {
    let inderxOf = this.photosList.indexOf(this.photoModal);

    // showMore if you at the end of Ultra but you are viewing photos by modal
    if ((inderxOf + 1) == this.photosList.length && this.disableInfiniteScroll && !this.showMore) {
      this.doShowMore();
    }

    // prevent agains double click next during loading new photos
    if ((inderxOf + 1) == this.photosList.length && this.isLoading === true) {
      return;
    }

    // if during laoding new photos you will go to previous photo and then go to next, last one, show spinner
    if ((inderxOf + 2) == this.photosList.length && this.isLoading === true) {
    }

    if ((inderxOf + 1) == this.photosList.length && !this.disableInfiniteScroll) {
      this.isLoading = true;

      this.getPhotosBatch().subscribe(result => {
        this.setArrows();
        if (!this.disableInfiniteScroll) {
          this.photoModal = this.photosList[inderxOf + 1];
        }
        this.isLoading = false;
      });
    }
    else if ((inderxOf + 1) == this.photosList.length && this.disableInfiniteScroll) {
      return;
    }
    else {
      this.photoModal = this.photosList[inderxOf + 1];
      this.setArrows();
    }
    this.setModalWidth();
  }

  setArrows() {
    let inderxOf = this.photosList.indexOf(this.photoModal);

    // if displayed photo is first one
    if (inderxOf == 0) {
      this.prevHidden = true
    }
    else {
      this.prevHidden = false;
    }

    // if displayed photo is last one
    if ((inderxOf + 1) == this.photosList.length && this.disableInfiniteScroll == true && this.showMore) {
      this.nextHidden = true
    }
    else {
      this.nextHidden = false;
    }
  }

  setModalWidth(): string {
    if (this.photoModal.width > (this.photoModal.height * 2)) {
      document.getElementsByClassName('modal-dialog')[0]?.classList.remove("modal-width-horizontal");
      document.getElementsByClassName('modal-dialog')[0]?.classList.remove("modal-width-vertical");
      document.getElementsByClassName('modal-dialog')[0]?.classList.add("modal-width-panorama");
      return "modal-width-panorama";
    }
    else if (this.photoModal.width >= this.photoModal.height) {
      document.getElementsByClassName('modal-dialog')[0]?.classList.remove("modal-width-panorama");
      document.getElementsByClassName('modal-dialog')[0]?.classList.remove("modal-width-vertical");
      document.getElementsByClassName('modal-dialog')[0]?.classList.add("modal-width-horizontal");
      return "modal-width-horizontal";
    }
    else {
      document.getElementsByClassName('modal-dialog')[0]?.classList.remove("modal-width-panorama");
      document.getElementsByClassName('modal-dialog')[0]?.classList.remove("modal-width-horizontal");
      document.getElementsByClassName('modal-dialog')[0]?.classList.add("modal-width-vertical");
      return "modal-width-vertical";
    }
  }

  //filtrForm(form: NgForm) {
  //  this.filter();
  //}

  filtrForm() {
    this.filter()
  }

  filtrModal(tags: string) {
    this.modalService.dismissAll();
    this.searchTags = tags;
    this.filter();
  }

  filtr(tags: string) {
    this.searchTags = tags;
    this.filter();
  }

  private filter() {
    this.photosList = [];
    this.skip = 0;
    this.disableInfiniteScroll = false;
    this.getPhotosBatch();
  }

  cleanTags() {
    this.searchTags = "";
  }

  splitTags(tags: string) {
    return tags?.split(',').map(e => e.trim());
  }

  getLocation(photoDetails: PhotoDetails) {
    const tags = [];
    if (photoDetails.country) {
      tags.push(photoDetails.country.trim());
    }

    if (photoDetails.voivodeship) {
      tags.push(photoDetails.voivodeship.trim());
    }

    if (photoDetails.city) {
      tags.push(photoDetails.city.trim());
    }
    return tags;
  }

  navigateToContact(id) {
    this.localizationService.getTranslation().subscribe(result => {
      let subject = result["AskForPhotoSubject"] + ' ' + id;
      this.modalService.dismissAll();
      this.router.navigate(['/contact', subject]);
    });
  }

  goToTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  private sliderShow() {
    this.images = ["../../assets/slidshowImages/slidshowImages1_black.jpg"
      , "../../assets/slidshowImages/slidshowImages2_black.jpg"
      , "../../assets/slidshowImages/slidshowImages3_black.jpg"
      , "../../assets/slidshowImages/slidshowImages4_black.jpg"
      , "../../assets/slidshowImages/slidshowImages5_black.jpg"
      , "../../assets/slidshowImages/slidshowImages6_black.jpg"];
  }

  //@HostListener('window:resize', ['$event'])
  //onResize(event) {
  //  //this.footerPosition = window.innerWidth >= 992 ? "fixed" : "relative"
  //  //this.footerPosition = this.noResults ? 'fixed' : 'relative';
  //  if (this.noResults && window.innerWidth >= 992 && window.innerWidth < 9999) {
  //    this.footerPosition = "fixed"
  //  }
  //  else if (this.noResults && window.innerWidth >= 500 && window.innerWidth < 800) {
  //    this.footerPosition = "relative"
  //  }
  //  else {
  //    this.footerPosition =  "relative"
  //  }
  //}
}
