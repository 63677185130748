import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Ceramics } from '../model/ceramics.model';
import { CeramicsService } from '../services/ceramics.service';
import { LocalizationService } from '../services/localization.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ImagePagination } from '../model/image-pagination.model';
import { NgForm } from '@angular/forms';
import { MediaType } from '../enumerations/media-type.enum';
import { LoggerService } from '../services/logger.service';
import { LogLevel } from '../enumerations/log-level.enum';
import { SEOService } from '../services/SEO.service';

@Component({
  selector: 'app-ceramics',
  templateUrl: './ceramics.component.html',
  styleUrls: ['./ceramics.component.css']
})
export class CeramicsComponent implements OnInit {
  private canonicalUrl: string = 'https://kasperwieliczko.com/ceramics';
  public title: string = 'Ceramics';
  public description: string = 'Kasper Wieliczko, stock of my abstract ceramics.'
  public ceramicsList: Ceramics[] = [];
  public disableInfiniteScroll: boolean = false;
  public doSpinnerHidden: boolean = true;
  public doGoToTopHidden: boolean = true;
  public searchTags: string = "";
  public hideFooter: boolean = true;
  public noResults: boolean = false;
  public slideShowImages = [];
  public mediaType = MediaType;
  private readonly batchSize: number
  private skip: number

  constructor(private ceramicsService: CeramicsService, public localizationService: LocalizationService, private router: Router, activeRoute: ActivatedRoute, private loggerService: LoggerService,
    private SEOService: SEOService) {
    this.skip = 0;
    this.batchSize = 5;

    let currentLang = activeRoute.snapshot.params["lang"];
    if (currentLang && currentLang !== localizationService.currentLang) {
      localizationService.switchLang(currentLang);
    }
    if (!currentLang) {
      localizationService.setUpLangUrl();
    }
  }

  ngOnInit() {
    this.SEO();
    this.sliderShow();
    this.getCeramicsBatch();
  }

  SEO() {
    this.SEOService.setTitle(this.title);
    this.SEOService.updateMetaTag({ name: 'description', content: this.description });
    this.SEOService.createLinkForCanonicalURL(this.canonicalUrl);
  }

  onScroll() {
    this.getCeramicsBatch()
  }

  @HostListener("window:scroll", ["$event"])
  onWindowScroll() {
    let innerHeight = window.innerHeight; //height of the window, height of the visible screen
    let scrollY = window.scrollY; //scolled down pixels
    let offsetHeight = document.body.offsetHeight; //height of the whole document, from the top to the bottom to which user need to scroll down
    let offset = scrollY / innerHeight;

    if (offset >= 0.6) {
      this.doGoToTopHidden = false
    }
    else {
      this.doGoToTopHidden = true
    }

    // if you're at the bottom of the page
    if ((innerHeight + scrollY) >= offsetHeight && !this.disableInfiniteScroll) {
      //this.getPhotosBatch();
    }
  }

  getCeramicsBatch() {
    this.doSpinnerHidden = false;
    this.disableInfiniteScroll = true;

    this.ceramicsService.getBatch(this.batchSize, this.skip, this.searchTags).subscribe(result => {
      result.forEach(e => this.setPagination(e))
      this.ceramicsList.push(...result);
      if (result.length === 0) {
        this.disableInfiniteScroll = true;
      }
      else {
        this.disableInfiniteScroll = false;
      }

      if (this.ceramicsList.length === 0) {
        this.noResults = true;
      }
      else {
        this.noResults = false;
      }

      this.hideFooter = !this.disableInfiniteScroll;

      if (result.length > 0 && result.length < this.batchSize) {
        this.hideFooter = false;
      }
      this.doSpinnerHidden = true;
      this.skip = this.skip + this.batchSize;
    }, error => {
      this.doSpinnerHidden = true;
      this.loggerService.log({ component: "CeramicsComponent", function: "getCeramicsBatch", error: error, logLevel: LogLevel.Error }).subscribe();
    });
  }

  prevImg(imagePagination: ImagePagination) {
    if (imagePagination.currentImage.key === 1) {
      return;
    }

    imagePagination.currentImage = imagePagination.imagesList.find(e => e.key == imagePagination.currentImage.key - 1);
    imagePagination.isLoading = imagePagination.currentImage.mediaType == MediaType.Photo ? true : false;
  }

  nextImg(imagePagination: ImagePagination) {
    if (imagePagination.currentImage.key === imagePagination.imagesList.length) {
      return;
    }

    var nextKey = imagePagination.currentImage.key + 1
    imagePagination.currentImage = imagePagination.imagesList.find(e => e.key == nextKey);
    imagePagination.isLoading = imagePagination.currentImage.mediaType == MediaType.Photo ? true : false;
  }

  private setPagination(ceramics: Ceramics) {
    ceramics.imagePagination = new ImagePagination();
    ceramics.imagePagination.currentImage = { key: 1, value: ceramics.imageUrl, mediaType: MediaType.Photo }
    ceramics.imagePagination.numberOfImages = ceramics.ceramicsImageChilds.length + 1;
    ceramics.imagePagination.imagesList = [{ key: 1, value: ceramics.imageUrl, mediaType: MediaType.Photo }];

    for (var i = 0; i < ceramics.ceramicsImageChilds.length; i++) {
      ceramics.imagePagination.imagesList[i + 1] = { key: i + 2, value: ceramics.ceramicsImageChilds[i].imageUrl, mediaType: ceramics.ceramicsImageChilds[i].mediaType }
    }
  }

  filtrForm() {
    this.filter()
  }

  filtr(tags: string) {
    this.searchTags = tags;
    this.filter();
  }

  private filter() {
    this.ceramicsList = [];
    this.skip = 0;
    this.disableInfiniteScroll = false;
    this.getCeramicsBatch();
  }

  cleanTags() {
    this.searchTags = "";
  }

  splitTags(tags: string) {
    return tags?.split(',').map(e => e.trim());
  }

  navigateToContact(id) {
    this.localizationService.getTranslation().subscribe(result => {
      let subject = result["AskForCeramicsSubject"] + ' ' + id;
      this.router.navigate(['/contact', subject]);
    });
  }

  goToTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  load(imagePagination: ImagePagination) {
    imagePagination.isLoading = false;
  }

  private sliderShow() {
    this.slideShowImages = ["../../assets/slidshowImages/ceramicsSliderShow1b_black.jpg",
      "../../assets/slidshowImages/ceramicsSliderShow2b_black.jpg",
    ];
  }
}
