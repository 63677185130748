import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AbstractRestService } from "./abstractRestService"
import { Ceramics } from "../model/ceramics.model"
import { IServiceInterface } from './serviceInterface';

@Injectable()
export class CeramicsService extends AbstractRestService<Ceramics> implements IServiceInterface<Ceramics>{

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    super(http, baseUrl + "api/ceramics/");
  }
}
