import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Observable, Subject } from "rxjs";
import { Login } from "../model/auth/login.model";
import { Registration } from "../model/auth/registration.model";

@Injectable()
export class AuthenticationService {
  private URL: string;

  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string, private jwtHelper: JwtHelperService) {
    this.URL = baseUrl + "api/auth/";
  }

  public registration = (body: Registration): Observable<Registration> => {
    return this._http.post<Registration>(this.URL + 'Registration',  body);
  }

  public login = (body: Login): Observable<Login> => {
    return this._http.post<Login>(this.URL + 'Login', body);
  }

  public logout = () => {
    localStorage.removeItem("jwt");
    localStorage.removeItem("currentUser");
  }

  isUserAuthenticated() {
    const token: string = localStorage.getItem("jwt");
    if (token && !this.jwtHelper.isTokenExpired(token)) {
      return true;
    }
    else {
      return false;
    }
  }

  public getUser(): string {
    return localStorage.getItem("currentUser");
  }
}
