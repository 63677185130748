<app-nav-menu></app-nav-menu>
<div class="container col-md-6 col-lg-4 mt-5 text-middle-dark">
  <h2>{{"Contact" | translate}}</h2>
  <address class="pb-4">
    <p>e-mail:	kasperwieliczko@kasperwieliczko.com</p>
  </address>

  <app-annotation [Model]="annotation"></app-annotation>

  <form class="contact-form mt-2 mb-2" [formGroup]="registerForm" (ngSubmit)="send()">
    <div class="form-group">
      <label>{{'EmailAddress' | translate}}</label>
      <input type="text" class="form-control" formControlName="fromAddress" [ngClass]="{ 'is-invalid': submitted && f.fromAddress.errors }" />
      <div *ngIf="submitted && f.fromAddress.errors" class="invalid-feedback">
        <div *ngIf="f.fromAddress.errors.required">{{'EmailIsRequired' | translate}}</div>
        <div *ngIf="f.fromAddress.errors.email">{{'EmailIsNotValid' | translate}}</div>
        <div *ngIf="f.fromAddress.errors.mustMatch">{{'EmailMustMatch' | translate}}</div>
      </div>
    </div>
    <div class="form-group">
      <label>{{'EmailAddressRepeat' | translate}}</label>
      <input type="text" class="form-control" formControlName="fromAddressRepeat" [ngClass]="{ 'is-invalid': submitted && f.fromAddressRepeat.errors }" />
      <div *ngIf="submitted && f.fromAddressRepeat.errors" class="invalid-feedback">
        <div *ngIf="f.fromAddressRepeat.errors.required">{{'EmailIsRequired' | translate}}</div>
        <div *ngIf="f.fromAddressRepeat.errors.email">{{'EmailIsNotValid' | translate}}</div>
        <div *ngIf="f.fromAddressRepeat.errors.mustMatch">{{'EmailMustMatch' | translate}}</div>
      </div>
    </div>
    <div class="form-group">
      <label>{{'Subject' | translate}}</label>
      <input type="text" class="form-control" formControlName="subject" [ngClass]="{ 'is-invalid': submitted && f.subject.errors }" />
      <div *ngIf="submitted && f.subject.errors" class="invalid-feedback">
        <div *ngIf="f.subject.errors.required">{{'SubjectIsRequired' | translate}}</div>
        <div *ngIf="f.subject.errors.maxlength">{{'SubjectMaxLength' | translate}}</div>
      </div>
    </div>
    <div class="form-group">
      <label>{{'Message' | translate}}</label>
      <textarea rows=6 class="form-control" formControlName="body" [ngClass]="{ 'is-invalid': submitted && f.body.errors }" placeholder="{{'ComposeMail' | translate}}"></textarea>
      <div *ngIf="submitted && f.body.errors" class="invalid-feedback">
        <div *ngIf="f.body.errors.required">{{'BodyIsRequired' | translate}}</div>
        <div *ngIf="f.body.errors.maxlength">{{'BodyMaxLength' | translate}}</div>
      </div>
    </div>
    <button class="btn btn-primary float-right mt-2">Send mail</button>
  </form>
</div>

<app-footer [footerPosition]="footerPosition"></app-footer>


