import { Component, HostListener, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SEOService } from '../services/SEO.service';
import { ActivatedRoute } from '@angular/router';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { shareReplay } from 'rxjs/operators';
import { LogLevel } from '../enumerations/log-level.enum';
import { Annotation } from '../model/annotation.model';
import { Email } from '../model/email.model';
import { ContactService } from '../services/contact.service';
import { LocalizationService } from '../services/localization.service';
import { LoggerService } from '../services/logger.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  private canonicalUrl: string = 'https://kasperwieliczko.com/contact';
  public title: string = 'Contact';
  public description: string = 'Contact with Kasper Wieliczko'
  public email: Email = new Email();
  public annotation: Annotation = new Annotation();
  public footerPosition: string;
  registerForm: UntypedFormGroup;
  submitted = false;

  constructor(private contact: ContactService, public localizationService: LocalizationService, activeRoute: ActivatedRoute, private formBuilder: UntypedFormBuilder,
    private recaptchaV3Service: ReCaptchaV3Service, private loggerService: LoggerService, private SEOService: SEOService) {

    let currentLang = activeRoute.snapshot.params["lang"];
    let subject = activeRoute.snapshot.params["subject"];

    if (subject) {
      this.email.subject = subject;
    }

    if (currentLang && currentLang !== localizationService.currentLang) {
      localizationService.switchLang(currentLang);
    }
  }

  ngOnInit(): void {
    this.SEO();
    this.registerForm = this.formBuilder.group({
      fromAddress: ['', [Validators.required, Validators.email]],
      fromAddressRepeat: ['', [Validators.required, Validators.email]],
      subject: [this.email.subject, [Validators.required, Validators.maxLength(200)]],
      body: ['', [Validators.required, Validators.maxLength(5000)]],
      token: null
    }, {
      validator: MustMatch('fromAddress', 'fromAddressRepeat')
    });

    this.footerPosition = window.innerWidth > 992 ? "fixed" : "relative"
  }

  SEO() {
    this.SEOService.setTitle(this.title);
    this.SEOService.updateMetaTag({ name: 'description', content: this.description });
    this.SEOService.createLinkForCanonicalURL(this.canonicalUrl);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.footerPosition = window.innerWidth > 992 ? "fixed" : "relative"
  }

  get f() { return this.registerForm.controls; }

  send() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }

    this.annotation.message = "";
    this.reCaptchaHandler().subscribe(token => {
      this.registerForm.patchValue({ token: token });
      this.contact.save(this.registerForm.getRawValue()).subscribe(
        result => {
          this.annotation.success = true;
          this.localizationService.getTranslation().subscribe(result => {
            this.annotation.message = result["EmailSentWithSuccess"];
          });
        },
        error => {
          this.annotation.error = true;
          this.localizationService.getTranslation().subscribe(result => {
            this.annotation.message = result["EmailSendFail"];
          });
        });
    });
  }

  reCaptchaHandler() {
    const reCaptchaHandler = this.recaptchaV3Service.execute('SendMail').pipe(shareReplay());;

    reCaptchaHandler.subscribe(
      (token) => {
        return token;
      },
      (error) => {
        this.loggerService.log({ component: "ContactComponent", function: "handleReCaptcha", error: error, logLevel: LogLevel.Error }).subscribe();
        this.annotation.error = true;
        this.localizationService.getTranslation().subscribe(result => {
          this.annotation.message = result["EmailSendFail"];
        });
      }
    );

    return reCaptchaHandler;
  }
}

// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: UntypedFormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  }
}
