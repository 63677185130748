import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AbstractRestService } from "./abstractRestService"
import {Photo} from "../model/photo.model"
import { IServiceInterface } from './serviceInterface';

@Injectable()
export class PhotoService extends AbstractRestService<Photo> implements IServiceInterface<Photo>{
    // constructor(http: HttpClient, configuration: Configuration) {
    //     super(http, configuration.serverWithApiUrl + "Driver/");
    // }

    constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
      super(http, baseUrl + "api/photo/");
    }
}
