<app-nav-menu></app-nav-menu>
<app-slide-show [images]="slideShowImages"></app-slide-show>

<div class="search-bar container">
  <ul class="nav justify-content-center">
    <li class="relative">
      <input type="text" autocomplete="off" class="searchinput yui-ac-input add-padding" [(ngModel)]="searchTags" placeholder="{{'SearchForCeramics' | translate}}">
      <button [hidden]="!searchTags" class="absolute search-clear-icon" type="button" style="display: inline-block;" (click)="cleanTags()"><i class="icon-close"></i></button>
      <button class="absolute search-btn" type="button" (click)="filtrForm()"><i class="icone-magnifying-glass search-icon"></i></button>
    </li>
  </ul>
</div>

<div class="container">
  <div class="go-to-top" [style.visibility]="doGoToTopHidden ? 'hidden' : 'visible'" (click)="goToTop()">&uarr;</div>
  <div infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [infiniteScrollDisabled]="disableInfiniteScroll" (scrolled)="onScroll()">
    <div *ngFor="let ceramics of ceramicsList" class="row-box">
      <div class="col-left centre">
        <app-progress-spinner [hidden]="!ceramics.imagePagination.isLoading" class="absolute"></app-progress-spinner>
        <img style="min-height:200px" *ngIf="ceramics.imagePagination.currentImage.mediaType == mediaType.Photo else videoTemplate"
             [src]="ceramics.imagePagination.currentImage.value" (load)="load(ceramics.imagePagination)" alt="{{ceramics.ceramicsDetails[0].name}}, {{ceramics.ceramicsDetails[0].tags}}, ceramics"/>
        <ng-template #videoTemplate>
          <video autoplay loop muted controls="controls">
            <source [src]="ceramics.imagePagination.currentImage.value" type="video/mp4">
            <source src="movie.ogg" type="video/ogg">
          </video>
        </ng-template>
        <p class="child-img-counter">{{ceramics.imagePagination.currentImage.key}}/{{ceramics.imagePagination.numberOfImages}}</p>
        <a role="button" class="carousel-control-prev" (click)="prevImg(ceramics.imagePagination)" [hidden]="ceramics.imagePagination.currentImage.key === 1">
          <span aria-hidden="true" class="c-arrow-left-bg-white wh-30"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a role="button" class="carousel-control-next" (click)="nextImg(ceramics.imagePagination)" [hidden]="ceramics.imagePagination.currentImage.key === ceramics.imagePagination.numberOfImages">
          <span aria-hidden="true" class="c-arrow-right-bg-white wh-30"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
      <div class="col-right">
        <div *ngFor="let ceramicsDetails of ceramics.ceramicsDetails">
          <div *ngIf="ceramicsDetails.language == localizationService.currentLang">
            <ul class="img-info">
              <li>
                <div class="row">
                  <div class="col-sm-6">
                    <span class="text-uppercase">{{'Title' | translate }}:</span>
                  </div>
                  <div class="col-sm-6">
                    <p class="font-weight-bold" title="title">{{ceramicsDetails.name}}</p>
                  </div>
                </div>
              </li>
              <li>
                <div class="row">
                  <div class="col-sm-6">
                    <span class="text-uppercase">ID:</span>
                  </div>
                  <div class="col-sm-6">
                    <p>{{ceramics.id}}</p>
                  </div>
                </div>
              </li>
              <li>
                <div class="row">
                  <div class="col-sm-6">
                    <span class="text-uppercase">{{'Size' | translate }}:</span>
                  </div>
                  <div class="col-sm-6">
                    <span>{{ceramics.height}} x {{ceramics.width}} x {{ceramics.depth}} cm</span>
                  </div>
                </div>
              </li>
              <li>
                <div class="row">
                  <div class="col-sm-6">
                    <span class="text-uppercase">{{'Weight' | translate }}:</span>
                  </div>
                  <div class="col-sm-6">
                    <span>{{ceramics.weight}} kg</span>
                  </div>
                </div>
              </li>
              <li>
                <div class="row">
                  <div class="col-sm-6">
                    <span class="text-uppercase">{{'Technique' | translate }}:</span>
                  </div>
                  <div class="col-sm-6">
                    <span>{{ceramicsDetails.technique}}</span>
                  </div>
                </div>
              </li>
              <li *ngIf="ceramicsDetails.tags">
                <div class="row">
                  <div class="col-sm-6">
                    <span class="text-uppercase">{{'Tags' | translate }}:</span>
                  </div>
                  <div class="col-sm-6">
                    <span role="button" class="link" *ngFor="let tag of splitTags(ceramicsDetails.tags)" (click)="filtr(tag)">#{{tag}} </span>
                  </div>
                </div>
              </li>
            </ul>

            <p>{{'PriceCeramics' | translate: {pricePLN: ceramics.pricePLN} }}</p>
            <span role="button" (click)="navigateToContact(ceramics.id)" class="link" style="font-size: 11px;">{{'AskForCeramics' | translate}}</span>
            <p class="font-weight-bold text copy-rights">&copy; {{'AllRightsReserved' | translate}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-progress-spinner [hidden]="doSpinnerHidden"></app-progress-spinner>
  <div [hidden]="!noResults" class="not-found"><strong>{{'NoResults' | translate}}</strong></div>
</div>

<app-footer [footerPosition]="noResults ? 'fixed' : 'relative'" [hidden]="hideFooter"></app-footer>
