import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalizationService } from '../../services/localization.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(public localizationService: LocalizationService, private router: Router, activeRoute: ActivatedRoute) { }

  @Input()
  footerPosition: string = ''

  ngOnInit(): void { }

  css() {
    if (this.footerPosition == "fixed") {
      return "footer_fixed";
    }
    else if (this.footerPosition == "relative") {
      return "footer_relative";
    }
    else {
      return "footer_relative";
    }
  }

  navigateTo(url: string) {
    window.open(url, "_blank")
  }
}
