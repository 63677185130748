<div class="footer" [ngClass]="css()">
  <div class="footer-links">
    <a routerLink="/{{localizationService.currentLang}}/photography">
      <strong>{{'Photography' | translate }}</strong>
    </a>
    <a routerLink="/{{localizationService.currentLang}}/painting">
      <strong>{{'Painting' | translate }}</strong>
    </a>
    <a routerLink="/{{localizationService.currentLang}}/ceramics">
      <strong>{{'Ceramics' | translate }}</strong>
    </a>
    <a routerLink="/{{localizationService.currentLang}}/about">
      <strong>{{'About me' | translate }}</strong>
    </a>
    <a routerLink="/{{localizationService.currentLang}}/contact">
      <strong> {{'Contact' | translate }}</strong>
    </a>
    <a class="icon-margin" href="https://www.instagram.com/kasperwieliczko/">
      <i class="icone-instagram icone-social-media-main-page"></i>
    </a>
    <a class="mr-1" href="https://www.facebook.com/kasper.wieliczko/">
      <i class="icone-facebook icone-social-media-main-page"></i>
    </a>

    <!--<i onclick="navigateTo(www.instagram.com/kasperwieliczko)" class="icon-margin icone-instagram icone-social-media-main-page"></i>
    <i onclick="navigateTo(www.facebook.com/kasper.wieliczko)" class="mr-1 icone-facebook icone-social-media-main-page"></i>-->
  </div>
  <div>
    <strong class="copy-rights-footer">
      &copy; {{'AllRightsReserved' | translate}}
    </strong>
  </div>
</div>
