import { MediaType } from "../enumerations/media-type.enum";
import { KeyValuePair } from "./keyValue.model";

export class ImagePagination {
  imagesList: { key: number, value: string, mediaType: MediaType }[];
  numberOfImages: number;
  currentImage: { key: number, value: string, mediaType: MediaType };
  nextUrl: string;
  prevUrl: string;
  isLoading: boolean = true;
}
