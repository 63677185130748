import { Component, HostListener, Inject, OnInit  } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Painting } from '../model/painting.model';
import { PaintingService } from '../services/painting.service';
import { ImagePagination } from '../model/image-pagination.model';
import { LocalizationService } from '../services/localization.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { MediaType } from '../enumerations/media-type.enum';
import { SEOService } from '../services/SEO.service';

@Component({
  selector: 'app-painting',
  templateUrl: './painting.component.html',
  styleUrls: ['./painting.component.css']
})
export class PaintingComponent implements OnInit {
  private canonicalUrl: string = 'https://kasperwieliczko.com/painting';
  public title: string = 'Paintings';
  public description: string = 'Kasper Wieliczko, stock of my abstract paintings.'
  public paintingsList: Painting[] = [];
  public disableInfiniteScroll: boolean = false;
  public doSpinnerHidden: boolean = true;
  public doGoToTopHidden: boolean = true;
  public searchTags: string = "";
  public hideFooter: boolean = true;
  public noResults: boolean = false;
  public slideShowImages = [];
  public mediaType = MediaType;
  private readonly batchSize: number
  private skip: number

  constructor(private paintingService: PaintingService, public localizationService: LocalizationService, private router: Router, activeRoute: ActivatedRoute,
    private SEOService: SEOService) {

    this.skip = 0;
    this.batchSize = 5;

    let currentLang = activeRoute.snapshot.params["lang"];
    if (currentLang && currentLang !== localizationService.currentLang) {
      localizationService.switchLang(currentLang);
    }
    if (!currentLang) {
      localizationService.setUpLangUrl();
    }
  }

  ngOnInit() {
    this.SEO()
    this.sliderShow();
    this.getPaintingBatch()
  }

  SEO() {
    this.SEOService.setTitle(this.title);
    this.SEOService.updateMetaTag({ name: 'description', content: this.description });
    this.SEOService.createLinkForCanonicalURL(this.canonicalUrl);
  }

  onScroll() {
    this.getPaintingBatch()
  }

  @HostListener("window:scroll", ["$event"])
  onWindowScroll() {
    let innerHeight = window.innerHeight; //height of the window, height of the visible screen
    let scrollY = window.scrollY; //scolled down pixels
    let offsetHeight = document.body.offsetHeight; //height of the whole document, from the top to the bottom to which user need to scroll down
    let offset = scrollY / innerHeight;

    if (offset >= 0.6) {
      this.doGoToTopHidden = false
    }
    else {
      this.doGoToTopHidden = true
    }

    // if you're at the bottom of the page
    if ((innerHeight + scrollY) >= offsetHeight && !this.disableInfiniteScroll) {
      //this.getPhotosBatch();
    }
  }

  getPaintingBatch() {
    this.doSpinnerHidden = false;
    this.disableInfiniteScroll = true;

    this.paintingService.getBatch(this.batchSize, this.skip, this.searchTags).subscribe(result => {
      result.forEach(e => this.setPagination(e))
      this.paintingsList.push(...result);
      if (result.length === 0) {
        this.disableInfiniteScroll = true;
      }
      else {
        this.disableInfiniteScroll = false;
      }

      if (this.paintingsList.length === 0) {
        this.noResults = true;
      }
      else {
        this.noResults = false;
      }

      this.hideFooter = !this.disableInfiniteScroll;

      if (result.length > 0 && result.length < this.batchSize) {
        this.hideFooter = false;
      }

      this.doSpinnerHidden = true;
      this.skip = this.skip + this.batchSize;
    }, error => {
      this.doSpinnerHidden = true;
      console.error(error)
    });
  }

  prevImg(imagePagination: ImagePagination) {
    if (imagePagination.currentImage.key === 1) {
      return;
    }

    imagePagination.currentImage = imagePagination.imagesList.find(e => e.key == imagePagination.currentImage.key - 1);
    imagePagination.isLoading = imagePagination.currentImage.mediaType == MediaType.Photo ? true : false;
  }

  nextImg(imagePagination: ImagePagination) {
    if (imagePagination.currentImage.key === imagePagination.imagesList.length) {
      return;
    }

    var nextKey = imagePagination.currentImage.key + 1
    imagePagination.currentImage = imagePagination.imagesList.find(e => e.key == nextKey);
    imagePagination.isLoading = imagePagination.currentImage.mediaType == MediaType.Photo ? true : false;
  }

  private setPagination(painting: Painting) {
    painting.imagePagination = new ImagePagination();
    painting.imagePagination.currentImage = { key: 1, value: painting.imageUrl, mediaType: MediaType.Photo }
    painting.imagePagination.numberOfImages = painting.paintingImageChilds.length + 1;
    painting.imagePagination.imagesList = [{ key: 1, value: painting.imageUrl, mediaType: MediaType.Photo }]

    for (var i = 0; i < painting.paintingImageChilds.length; i++) {
      painting.imagePagination.imagesList[i + 1] = { key: i + 2, value: painting.paintingImageChilds[i].imageUrl, mediaType: painting.paintingImageChilds[i].mediaType }
    }
  }

  filtrForm() {
    this.filter()
  }

  filtr(tags: string) {
    this.searchTags = tags;
    this.filter();
  }

  private filter() {
    this.paintingsList = [];
    this.skip = 0;
    this.disableInfiniteScroll = false;
    this.getPaintingBatch();
  }

  cleanTags() {
    this.searchTags = "";
  }

  splitTags(tags: string) {
    return tags?.split(',').map(e => e.trim());
  }

  navigateToContact(id) {
    this.localizationService.getTranslation().subscribe(result => {
      let subject = result["AskForPaintingSubject"] + ' ' + id;
      this.router.navigate(['/contact', subject]);
    });
  }

  goToTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  load(imagePagination: ImagePagination) {
    imagePagination.isLoading = false;
  }

  private sliderShow() {
    this.slideShowImages = ["../../assets/slidshowImages/paintingSliderShow6_black.jpg",
      "../../assets/slidshowImages/paintingSliderShow3_black.jpg",
      "../../assets/slidshowImages/paintingSliderShow4_black.jpg",
      "../../assets/slidshowImages/paintingSliderShow5_black.jpg"
    ];
  }
}
