import { Injectable, OnInit ,Optional, SkipSelf } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common'; 

@Injectable()
export class LocalizationService {
  public currentLang: string
  public langs: string[];
  public defaultLang = 'en';

  public flagMapping = [
    { lang: "en", value: "../../assets/flags/4x3/gb.svg" },
    { lang: "de", value: "../../assets/flags/4x3/de.svg" },
    { lang: "ru", value: "../../assets/flags/4x3/ru.svg" },
    { lang: "pl", value: "../../assets/flags/4x3/pl.svg" }];

  constructor(private translateService: TranslateService, private activeRoute: ActivatedRoute, private location: Location) {
    //let _localeId = localStorage.getItem('language') || 'en-US';
    translateService.addLangs(['en', 'pl']);
    translateService.addLangs(['en', 'de']);
    translateService.addLangs(['en', 'ru']);
    this.langs = translateService.getLangs();

    translateService.setDefaultLang(this.defaultLang);
    translateService.use(this.defaultLang);
    this.currentLang = this.defaultLang;
    this.onInit();
    this.getPosts();
  }

  onInit() {
    let lastSeg = window.location.href.substr(window.location.href.lastIndexOf('/') + 1);
    let browserLang = this.translateService.getBrowserLang();

    if (lastSeg && this.langs.includes(lastSeg) && this.translateService.currentLang === lastSeg) {
      return;
    }
    else if (lastSeg && this.langs.includes(lastSeg) && this.translateService.currentLang !== lastSeg) {
      this.switchLang(lastSeg);
    }
    else if (browserLang && this.langs.includes(browserLang) && this.translateService.currentLang !== browserLang) {
      this.switchLang(browserLang);
    }
  }

  switchLang(lang: string) {
    this.currentLang = lang;
    this.translateService.use(lang);
  }

  getPosts() {
    this.translateService.onLangChange.subscribe((params: LangChangeEvent) => {
      const doAdminPanel = window.location.href.indexOf('adminpanel') !== -1;
      if (doAdminPanel === true) {
        return;
      }
      this.setUpLangUrl();
    });
  }

  setUpLangUrl() {
    var params = window.location.pathname.split('/').filter(el => el);
    var url = '/' + this.currentLang;
    var i = 0;
    //if url already contains land segment skip it 
    if (params && params.length != 0 && this.flagMapping.find(x => x.lang === params[0])) {
      i = 1;
    }

    for (i; i < params.length; i++) {
      url += '/' + params[i];
    }
    this.location.replaceState(url); 
  }

  getTranslation() {
    return this.translateService.getTranslation(this.currentLang)
  }

  public getflag(lang: string) {
    return this.flagMapping.find(f => f.lang == lang).value;
  }
}
