<div class="carousel slide min-h" data-ride="carousel">
  <app-progress-spinner [hidden]="doHidden" class="slider-show-spinner"></app-progress-spinner>
  <ngb-carousel *ngIf="images && images.length !== 0" style="min-height:120px">
    <ng-template ngbSlide *ngFor="let image of images; let i = index">
      <div class="picsum-img-wrapper">
        <img [src]="image" alt="Slide no {{i + 1}}" (load)="load()">
      </div>
    </ng-template>
  </ngb-carousel>
</div>
