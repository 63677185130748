import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";

@Injectable({
  providedIn: 'root'
})
//@Injectable()
export class SEOService {
  canonicalFlag = false;

  constructor(private titleService: Title, private metaService: Meta, @Inject(DOCUMENT) private doc: any) {

  }

  public setTitle(title) {
    this.titleService.setTitle(title);
  }

  public updateMetaTag(input) {
    this.metaService.updateTag(input);
  }

  public createLinkForCanonicalURL(url: string) {
    if (!this.canonicalFlag) {
      url = undefined;
    }

    let existingLink = this.doc.querySelector('link[rel="canonical"]');
    if (existingLink) {
      this.doc.head.removeChild(existingLink);
    }

    let link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.doc.head.appendChild(link);
    link.setAttribute('href', url ? url : this.doc.URL);
  }
} 
