import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnnotationComponent } from './annotation/annotation.component';
import { ProgressSpinnerComponent } from './progress-spinner/progress-spinner.component';
import { FooterComponent } from './footer/footer.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { LoggerService } from '../services/logger.service';

@NgModule({
  declarations: [AnnotationComponent, ProgressSpinnerComponent, FooterComponent],
  imports: [
    CommonModule, TranslateModule, RouterModule
  ],
  providers: [LoggerService],
  exports: [AnnotationComponent, ProgressSpinnerComponent, FooterComponent]
})
export class ShareModule { }
