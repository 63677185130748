import { Component } from '@angular/core';
import { LocalizationService } from '../services/localization.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent {
  isExpanded = false;

  constructor(public localizationService: LocalizationService, private authenticationService: AuthenticationService) { }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  get isAuthenticated(): boolean {
    return this.authenticationService.isUserAuthenticated()
  }

  get user(): string {
    return this.authenticationService.getUser()
  }

  logOut() {
    this.authenticationService.logout()
  }
}
