import { Injectable, Inject, InjectionToken } from "@angular/core";
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { Resource } from "../model/resource.model";
import { MediaType } from "../enumerations/media-type.enum";

export abstract class AbstractRestService<T extends Resource> {
  constructor(protected _http: HttpClient, protected url: string) {
  }

  getAll(): Observable<T[]> {
    // return this._http.get(this.url) as Observable<T[]>;
    return this.sendRequest<T[]>("GET", this.url);
  }

  getById(id: number): Observable<T> {
    // return this._http.get(`${this.url}${id}`) as Observable<T>;
    return this.sendRequest<T>("GET", `${this.url}${id}`);
  }

  getBatch(batchSize: number, skip: number, tags: string = ''): Observable<T[]> {
    return this.sendRequest<T[]>("GET", `${this.url}batch?batchSize=${batchSize}&skip=${skip}&tags=${tags}`)
  }

  getBatchAuth(batchSize: number, skip: number): Observable<T[]> {
    return this.sendRequest<T[]>("GET", `${this.url}batchauth?batchSize=${batchSize}&skip=${skip}`)
  }

  save(item: T): Observable<T> {
    return this.sendRequest<T>("POST", this.url, item);
  }

  update(item: T): Observable<T> {
    return this.sendRequest<T>("PUT", `${this.url}/${item.id}`, item);
  }

  delete(id: number): Observable<T> {
    return this.sendRequest<T>("DELETE", `${this.url}${id}`);
  }

  deleteChild(parentId: number, childId: number): Observable<T> {
    return this.sendRequest<T>("DELETE", `${this.url}${parentId}/${childId}`);
  }

  uploadImage(file: File, parentId: number = null, mediaType: MediaType = null): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append('File', file);
    if (parentId !== null && parentId !== undefined) {
      formData.append('ParentId', parentId.toString());
    }

    if (mediaType !== null && mediaType !== undefined) {
      formData.append('MediaType', mediaType.toString());

    }

    const req = new HttpRequest('POST', `${this.url}uploadImage`, formData, {
      reportProgress: true,
      responseType: 'json'
    });

    return this._http.request(req);
  }

  translate(text: string, lang: string): Observable<T> {
    let APIKey = "trnsl.1.1.20190112T210024Z.323266674c33304d.ca2cb141c96c4e288cd7e73f575dbb5238dd1deb";
    let UrlTranslateLanguage = "https://translate.yandex.net/api/v1.5/tr.json/translate?key={0}&text={1}&lang={2}";
    let uri = UrlTranslateLanguage.replace('{0}', APIKey).replace('{1}', text).replace('{2}', lang);
    return this.sendRequest("GET", uri);
  }

  protected sendRequest<T>(verb: string, url: string, body?: T): Observable<T> {
    //  TODO
    let myHeaders = new HttpHeaders();
    myHeaders = myHeaders.set("Access-Key", "<secret>");
    myHeaders = myHeaders.set("Application-Names", ["exampleApp", "proAngular"]);

    return this._http.request<T>(verb, url, {
      body: body,
      headers: myHeaders
    }).pipe(catchError((error: Response) =>
      throwError(`Network Error: ${error.statusText} (${error.status})`)));
  }
}
