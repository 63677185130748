import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'app';

  keyWords = 'Kasper Wieliczko, Kasper, Wieliczko, photography, landscape, great photographers, photographers, photography images, photography, photos, sell photos online, sell your photos, share photos, your photos, ceramics, abstract, abstract painting'

  constructor(metaService: Meta) {
    metaService.addTags([
      { name: 'keywords', content: this.keyWords },
      { name: 'author', content: 'Kasper Wieliczko' },
      { name: 'robots', content: 'index, follow' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { 'httpEquiv': 'Content-Type', content: 'text/html; charset=utf-8' },
      { charset: 'UTF-8' }
    ]);

  }
}
