import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable, NgZone } from "@angular/core";
import { LoggerService } from "../services/logger.service";
import { LogLevel } from "../enumerations/log-level.enum"

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private loggerService: LoggerService,
    private zone: NgZone
  ) { }

  handleError(error: any) {
    // Check if it's an error from an HTTP response
    //if (!(error instanceof HttpErrorResponse)) {
    //  this.loggerService.log({
    //    component: "Undefined Component",
    //    function: "Undefined function",
    //    error: error,
    //    logLevel: LogLevel.Error,
    //  }).subscribe();
    //}
    this.zone.run(() =>
      this.loggerService.log({
        component: "Undefined Component",
        function: "Undefined function",
        error: error,
        logLevel: LogLevel.Error,
      }).subscribe());
    
    console.error('Error from global error handler', error);
  }
}
