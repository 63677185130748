import { Component, Input, OnInit } from '@angular/core';
import { Annotation } from '../../model/annotation.model';

@Component({
  selector: 'app-annotation',
  templateUrl: './annotation.component.html',
  styleUrls: ['./annotation.component.css']
})
export class AnnotationComponent implements OnInit {

  @Input() Model: Annotation;

  constructor() { }

  ngOnInit() { }

  css() {
    if (this.Model.success) {
      return "alert alert-success";
      //return { 'background-color': 'green' };
    }
    else if (this.Model.warning) {
      return "alert alert-warning";
      //return { 'background-color': 'yellow' };
    }
    else if (this.Model.error) {
      return "alert alert-danger";
      //return { 'background-color': 'red' };
    }
  }
}
