import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-slide-show',
  templateUrl: './slide-show.component.html',
  styleUrls: ['./slide-show.component.css']
})

export class SlideShowComponent implements OnInit {

  public doHidden: boolean = false;

  @Input() public images = [];

  constructor(config: NgbCarouselConfig, private route: ActivatedRoute) {
    config.interval = 2500;
    config.pauseOnHover = false;
  }

  ngOnInit() { }

  load() {
    this.doHidden = true;
  }
}
